import { Text, Stack } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import BlogImage from "../../components/blog/BlogImage";
import { useEffect } from "react";

const MrsLupsaiu = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <title>biojameskim | Dear Mrs. Lupsaiu</title>
      </Helmet>

      <Text
        className="blog-title"
        fontSize="3rem"
        align={"center"}
        fontWeight={"medium"}
        pt={"12vh"}
      >
        Dear Mrs. Lupsaiu
      </Text>

      <div className="blog-text">
        <Text
          fontFamily="century"
          fontSize="2xl"
          align={"center"}
          fontWeight={"medium"}
          pt={"4vh"}
          pb={"10vh"}
        >
          December 2022
        </Text>

        <Stack
          spacing={7}
          width={{ base: "85%", md: "50%" }}
          fontSize={{ base: "17", md: "md" }}
          fontFamily="century"
        >
          <Text pb="7vh">December 26, 2022</Text>

          <Text pb="3vh">Dear Mrs. Lupsaiu,</Text>

          <Text>I actually knew about you long before high school.</Text>
          <Text>
            I heard countless stories from my brother and his friends about how
            you were a really great teacher who cared a lot about her students.
          </Text>
          <Text>But they also gave me a warning.</Text>
          <Text>
            They said you were one of the scariest teachers at King High School.
          </Text>
          <Text>
            There were rumors going around that before you became a teacher at
            King, you used to be a sniper in Romania.
          </Text>
          <Text>
            Some said you were the best sniper in Romania before you fled to
            pursue teaching.
          </Text>
          <Text pb="5vh">
            Others even said you kept a sniper rifle hidden away somewhere in
            your classroom.
          </Text>

          <Text>
            So going into high school, when I saw that you were my teacher for
            Precalculus,
          </Text>
          <Text>I was intimidated.</Text>
          <Text pb="5vh">And a bit scared.</Text>

          <Text pb="5vh">
            I was just a clueless little freshman whose imagination had painted
            baseless pictures of you inside my head.
          </Text>

          <Text>
            It didn’t help that when I walked into your classroom on that first
            day,
          </Text>
          <Text>I saw that my assigned seat was right in the front.</Text>
          <Text>Right next to you.</Text>

          <Text fontWeight="bold" paddingY={"7vh"}>
            —
          </Text>

          <Text>As I got to know you more throughout the school year,</Text>
          <Text pb="5vh">
            I started to confirm some of my preconceptions about you.
          </Text>

          <Text>
            Just like my brother said, you were a great (and strict) teacher.
          </Text>
          <Text>
            And just like the rumors said, you told our class you used to be a
            sniper back in Romania.
          </Text>
          <Text>
            But it didn’t take long for me to realize that underneath all my
            preconceptions of you,
          </Text>
          <Text>there was something more.</Text>
          <Text>Something deeper.</Text>
          <Text pb="5vh">Something that no one had told me about before.</Text>

          <Text pb="5vh">
            Buried under the rumors and my imagination, there was a really great
            person and mentor.
          </Text>

          <Text>
            In the classroom, your constant encouragements and compliments
            motivated me to study in ways that I never studied before.
          </Text>
          <Text>
            You would frequently tell me that I was one of the best students
            you’ve had over the many years you’ve been teaching, and whether
            that was true or not, it brought a smile to my face.
          </Text>
          <Text pb="5vh">
            I would always walk out of your classroom feeling excited for the
            next day, and I found myself wanting to come back to your class to
            learn more and more.
          </Text>

          <Text>
            Outside the classroom, you taught me to stand firm in my opinions
            and I really enjoyed the times when you would share your unfiltered
            thoughts.
          </Text>
          <Text>
            You would complain about America’s education system, the things you
            wish you could fix at King, and what I could do to make a
            difference.
          </Text>
          <Text>
            I would always walk out of your classroom feeling inspired, feeling
            like I could do something to make a change in the world around me.
          </Text>

          <Text fontWeight="bold" paddingY={"7vh"}>
            —
          </Text>

          <Text>
            Our relationship continued even throughout my sophomore year when
            you were my teacher for Calculus.
          </Text>
          <Text>
            When I missed class because of tennis matches, you were always
            willing to stay after school ended to cover the material I missed,
            and our talks about math would lead into talks about my family, my
            career, and my future.
          </Text>
          <Text>
            Whenever I visited your classroom, you told me I could sleep on the
            couch in your room if I ever needed a quick break, and that I could
            store my books or clothes in your cabinets if I ever needed some
            storage space.
          </Text>
          <Text pb="5vh">You cared for me like a mother.</Text>

          <Text>
            I remember when I first told you that I was going to Cornell, I
            thought you would congratulate me, but you told me that I should’ve
            been going to Harvard or MIT instead haha.
          </Text>
          <Text>
            But immediately after, you told me that the winters at Cornell are
            cold and that I would need a thick parka and winter boots.
          </Text>
          <Text>
            You started looking up different stores where I could buy a parka
            and boots, and you told me to go to a place that you went with your
            son a couple weeks ago because they have the best deals.
          </Text>
          <Text>
            And before I left for college, you told me to buy one more thing.
          </Text>
          <Text>You told me to bring you a magnet from Cornell.</Text>
          <Text>A souvenir that you could display in your classroom.</Text>

          <Text fontWeight="bold" paddingY={"7vh"}>
            —
          </Text>

          <Text>
            My freshman year of college was packed with new and exciting
            changes, and there was so much I wanted to share with you about.
          </Text>
          <Text>So with the magnet in my hand,</Text>
          <Text pb="5vh">
            I went to visit you as soon as I got back home from college.
          </Text>

          <Text>
            But your familiar classroom looked a little different this time
            around.
          </Text>
          <Text>
            Your door was always propped open, but this time it was closed.
          </Text>
          <Text>
            Your lights were always bright and welcoming, but this time it was
            dark.
          </Text>
          <Text pb="5vh">
            Your desk was always occupied by a familiar face, but this time it
            was empty.
          </Text>

          <Text>I found out soon after that you weren’t feeling well.</Text>
          <Text pb="5vh">
            My heart dropped when I heard your cancer prognosis wasn’t great.
          </Text>

          <Text>I wish there was more time.</Text>
          <Text>
            I never got the chance to fully express how thankful I am to you.
          </Text>
          <Text>How thankful I am to you for guiding my aspirations.</Text>
          <Text>How thankful I am to you for caring for me like a mother.</Text>
          <Text pb="5vh">
            How thankful I am to you for affirming who I was and for encouraging
            me to find my place in this world.
          </Text>

          <Text>I really wish there was more time.</Text>
          <Text>I have so much to share with you and update you about.</Text>
          <Text>
            I never bought winter boots but I bought a really thick parka that
            kept me warm during my first winter in New York.
          </Text>
          <Text>
            And I think I finally found what I want to study and what to do with
            my career.
          </Text>

          <Text fontWeight="bold" paddingY={"7vh"}>
            —
          </Text>

          <Text>Stay strong, Mrs. Lupsaiu.</Text>
          <Text>I’ll be waiting for the day you return to your classroom.</Text>
          <Text>
            The day your door is propped open again and your lights welcome me
            in.
          </Text>
          <Text pb="5vh">
            The day you’re sitting back at your desk with your familiar smile
            warming the room.
          </Text>

          <Text>And when that day comes,</Text>
          <Text pb="7vh">I’ll be back.</Text>

          <Text>Back to say hi,</Text>
          <Text>Back to say thank you,</Text>
          <Text pb="15vh">And of course,</Text>

          <Text pb="20vh">back to give you your magnet.</Text>

          <Text pb="1vh">Missing you,</Text>
          <Text>James</Text>

          <BlogImage
            maxW="450px"
            src="../images/blog_pictures/Dear-Mrs-Lupsaiu/Pictures/me and lupsaiu.jpeg"
            alt="A picture of me and Mrs. Lupsaiu"
            caption="Mrs. Lupsaiu"
            caption2="May 2021"
            orientation="v"
          />

          <Text pb="10vh"> - James Kim</Text>

          <Text textAlign="center" fontWeight="bold" pb="4vh"></Text>
          <Text textAlign="center">
            Shortly after I wrote this, Mrs. Lupsaiu passed away on January 11,
            2023.
          </Text>
          <Text textAlign="center">
            My heart hurts over the fact that you can never know when the last
            time will be.
          </Text>
          <Text textAlign="center" pb="5vh">
            When the last goodbye will be.
          </Text>
          <Text textAlign="center">Thank you, Mrs. Lupsaiu.</Text>
          <Text textAlign="center" pb="20vh">
            You will be missed.
          </Text>
        </Stack>
      </div>
    </div>
  );
};

export default MrsLupsaiu;
